<template>
  <section id="news_main_arae" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <h2 class="text-coral" style="text-align: left">
            Haz que tu hotel destaque y venda mas
          </h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="news_item_boxed">
            <div class="news_item_img">
              <div class="news_author_img">
                <img src="../../assets/img/news/author-1.png" alt="img" />
              </div>
            </div>
            <div class="news_item_content">
              <h3>Visibilidad global instantánea</h3>
              <p>
                Tu hotel se muestra ante millones de viajeros en todo el mundo. Selah
                ayuda a posicionar tu propiedad en los primeros resultados, atrayendo
                clientes internacionales y locales sin necesidad de invertir en publicidad
                propia.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="news_item_boxed">
            <div class="news_item_img">
              <div class="news_author_img">
                <img src="../../assets/img/news/author-1.png" alt="img" />
              </div>
            </div>
            <div class="news_item_content">
              <h3>Más reservas, menos esfuerzo.</h3>
              <p>
                Apoya la toma de decisión de los viajeros al ofrecer procesos de reserva
                rápidos y promociones exclusivas. Esto impulsa la ocupación de tu hotel,
                generando más ingresos.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="news_item_boxed">
            <div class="news_item_img">
              <div class="news_author_img">
                <img src="../../assets/img/news/author-1.png" alt="img" />
              </div>
            </div>
            <div class="news_item_content">
              <h3>Confianza y reputación online</h3>
              <p>
                Estar en una Selah le da a tu hotel credibilidad y respaldo. Las reseñas y
                calificaciones de otros huéspedes crean una imagen positiva y atraen
                nuevos clientes que buscan calidad y seguridad en su elección.
              </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12 col-12">
          <div class="news_item_boxed">
            <div class="news_item_img">
              <div class="news_author_img">
                <img src="../../assets/img/news/author-1.png" alt="img" />
              </div>
            </div>
            <div class="news_item_content">
              <h3>Acceso a datos y tecnología</h3>
              <p>
                Selah ofrece herramientas de análisis que te permiten conocer mejor a tus
                clientes y ajustar tus tarifas y promociones. Esta tecnología ayuda a
                optimizar cada reserva, mejorando la rentabilidad de tu hotel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "NewsArea",
};
</script>
