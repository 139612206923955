<template>
  <section id="tour_details_main" class="section_padding">
    <div class="container">
      <div class="col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="section_heading_center">
          <h2>{{ $t("privacy_policies") }}</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="terms_service_content">
            <div class="terms_item" style="text-align: justify">
              <ol>
                <li>
                  <p>
                    <strong>{{ $t("introduction") }}</strong>
                  </p>
                  <p>{{ $t("int_p1") }}</p>
                  <p>{{ $t("int_p2") }}</p>
                  <p>{{ $t("int_p3") }}</p>
                  <p>{{ $t("int_p4") }}</p>
                  <p>{{ $t("int_p5") }}</p>
                  <p>{{ $t("int_p6") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("information") }}</strong>
                  </p>
                  <p>{{ $t("in_p1") }}</p>
                  <p>{{ $t("in_p2") }}</p>
                  <p>{{ $t("in_p3") }}</p>
                  <p>{{ $t("in_p4") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("definitions") }}</strong>
                  </p>
                  <p>{{ $t("df_p") }}</p>
                  <p>
                    <strong>{{ $t("authorization") }}</strong
                    >{{ $t("df_p1") }}
                  </p>
                  <p>
                    <strong>{{ $t("databases") }}</strong
                    >{{ $t("df_p2") }}
                  </p>
                  <p>
                    <strong>{{ $t("personal_data") }}</strong
                    >{{ $t("df_p3") }}
                  </p>
                  <p>
                    <strong>{{ $t("sensitive_data") }}</strong
                    >{{ $t("df_p4") }}
                  </p>
                  <p>
                    <strong>{{ $t("data_controller_1") }}</strong
                    >{{ $t("df_p5") }}
                  </p>
                  <p>
                    <strong>{{ $t("inversiones_shl") }}</strong
                    >{{ $t("df_p6") }}alexperezfer@gmail.com.
                  </p>
                  <p>
                    <strong>{{ $t("data_controller_2") }}</strong
                    >{{ $t("df_p7") }}
                  </p>
                  <p>
                    <strong>{{ $t("processing") }}</strong
                    >{{ $t("df_p8") }}
                  </p>
                  <p>
                    <strong>{{ $t("holder") }}</strong
                    >{{ $t("df_p9") }}
                  </p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("legal_framework") }}</strong>
                  </p>
                  <p>{{ $t("ml_1") }}</p>
                  <p>{{ $t("ml_2") }}</p>
                  <p>{{ $t("ml_3") }}</p>
                  <p>{{ $t("ml_4") }}</p>
                  <p>{{ $t("ml_5") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("fundamental_principles") }}</strong>
                  </p>
                  <p>{{ $t("pf_p") }}</p>
                  <p>
                    <strong>{{ $t("pf_p1") }}</strong
                    >{{ $t("pf_p1_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p2") }}</strong
                    >{{ $t("pf_p2_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p3") }}</strong
                    >{{ $t("pf_p3_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p4") }}</strong
                    >{{ $t("pf_p4_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p5") }}</strong
                    >{{ $t("pf_p5_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p6") }}</strong
                    >{{ $t("pf_p6_p1") }}
                  </p>
                  <p>{{ $t("pf_p6_p2") }}</p>
                  <p>
                    <strong>{{ $t("pf_p7") }}</strong
                    >{{ $t("pf_p7_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p8") }}</strong
                    >{{ $t("pf_p8_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p9") }}</strong
                    >{{ $t("pf_p9_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p10") }}</strong
                    >{{ $t("pf_p10_p") }}
                  </p>
                  <p>
                    <strong>{{ $t("pf_p11") }}</strong
                    >{{ $t("pf_p11_p1") }}
                  </p>
                  <p>{{ $t("pf_p11_p2") }}</p>
                  <p>{{ $t("pf_p11_p3") }}</p>
                  <p>{{ $t("pf_p11_p4") }}</p>
                  <p>{{ $t("pf_p11_p5") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("rights_data_subjects") }}</strong>
                  </p>
                  <p>{{ $t("rds_p1") }}</p>
                  <p>{{ $t("rds_p2") }}</p>
                  <p>{{ $t("rds_p3") }}</p>
                  <p>{{ $t("rds_p4") }}</p>
                  <p>{{ $t("rds_p5") }}</p>
                  <p>{{ $t("rds_p6") }}</p>
                  <p>{{ $t("rds_p7") }}</p>
                  <p>{{ $t("rds_p8") }}</p>
                  <p>{{ $t("rds_p9") }}</p>
                  <p>{{ $t("rds_p10") }}</p>
                  <p>{{ $t("rds_p11") }}</p>
                  <p>{{ $t("rds_p12") }}</p>
                  <p>{{ $t("rds_p13") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("duties_data_controller") }}</strong>
                  </p>
                  <p>{{ $t("ddc_p1") }}</p>
                  <p>{{ $t("ddc_p2") }}</p>
                  <p>{{ $t("ddc_p3") }}</p>
                  <p>{{ $t("ddc_p4") }}</p>
                  <p>{{ $t("ddc_p5") }}</p>
                  <p>{{ $t("ddc_p6") }}</p>
                  <p>{{ $t("ddc_p7") }}</p>
                  <p>{{ $t("ddc_p8") }}</p>
                  <p>{{ $t("ddc_p9") }}</p>
                  <p>{{ $t("ddc_p10") }}</p>
                  <p>{{ $t("ddc_p11") }}</p>
                  <p>{{ $t("ddc_p12") }}</p>
                  <p>{{ $t("ddc_p13") }}</p>
                  <p>{{ $t("ddc_p14") }}</p>
                  <p>{{ $t("ddc_p15") }}</p>
                  <p>{{ $t("ddc_p16") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("informacion_collected") }}</strong>
                  </p>
                  <p>{{ $t("ic_p1") }}</p>
                  <p>{{ $t("ic_p2") }}</p>
                  <p>{{ $t("ic_p3") }}</p>
                  <p>{{ $t("ic_p4") }}</p>
                  <p>{{ $t("ic_p5") }}</p>
                  <p>{{ $t("ic_p6") }}</p>
                  <p>{{ $t("ic_p7") }}</p>
                  <p>{{ $t("ic_p8") }}</p>
                  <p>{{ $t("ic_p9") }}</p>
                  <p>{{ $t("ic_p10") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("authorization_processing_personal_data") }}</strong>
                  </p>
                  <p>{{ $t("ap_p1") }}</p>
                  <p>{{ $t("ap_p2") }}</p>
                  <p>{{ $t("ap_p3") }}</p>
                  <p>{{ $t("ap_p4") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("purposes_personal_data_processing") }}</strong>
                  </p>
                  <p>{{ $t("pp_p1") }}</p>
                  <p>{{ $t("pp_p2") }}</p>
                  <p>{{ $t("pp_p3") }}</p>
                  <p>{{ $t("pp_p4") }}</p>
                  <p>{{ $t("pp_p5") }}</p>
                  <p>{{ $t("pp_p6") }}</p>
                  <p>{{ $t("pp_p7") }}</p>
                  <p>{{ $t("pp_p8") }}</p>
                  <p>{{ $t("pp_p9") }}</p>
                  <p>{{ $t("pp_p10") }}</p>
                  <p>{{ $t("pp_p11") }}</p>
                  <p>{{ $t("pp_p12") }}</p>
                  <p>{{ $t("pp_p13") }}</p>
                  <p>{{ $t("pp_p14") }}</p>
                  <p>{{ $t("pp_p15") }}</p>
                  <p>{{ $t("pp_p16") }}</p>
                  <p>{{ $t("pp_p17") }}</p>
                  <p>{{ $t("pp_p18") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("procedure_processing_personal_data") }}</strong>
                  </p>
                  <p>{{ $t("ppp_p1") }}</p>
                  <p>{{ $t("ppp_p2") }}</p>
                  <p>{{ $t("ppp_p3") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("procedure_crud_authorization") }}</strong>
                  </p>
                  <p>{{ $t("pca_p1") }}</p>
                  <p>{{ $t("pca_p2") }}</p>
                  <p>{{ $t("pca_p3") }}</p>
                  <p>{{ $t("pca_p4") }}</p>
                  <p>{{ $t("pca_p5") }}</p>
                  <p>{{ $t("pca_p6") }}</p>
                  <p>{{ $t("pca_p7") }}</p>
                  <p>{{ $t("pca_p8") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("area_responsible_processing_personal_data") }}</strong>
                  </p>
                  <p>{{ $t("arp_p1") }}</p>
                  <p>{{ $t("arp_p2") }}alexperezfer@gmail.com{{ $t("arp_p2_1") }}</p>
                  <p>{{ $t("arp_p3") }}</p>
                  <p>
                    <strong>{{ $t("inquiries") }}</strong>
                  </p>
                  <p>{{ $t("inq_p1") }}</p>
                  <p>{{ $t("inq_p2") }}</p>
                  <p>{{ $t("inq_p3") }}</p>
                  <p>{{ $t("inq_p4") }}</p>
                  <p>{{ $t("inq_p5") }}</p>
                  <p>
                    <strong>{{ $t("complaints") }}</strong>
                  </p>
                  <p>{{ $t("com_p1") }}</p>
                  <p>{{ $t("com_p2") }}</p>
                  <p>{{ $t("com_p3") }}</p>
                  <p>{{ $t("com_p4") }}</p>
                  <p>{{ $t("com_p5") }}</p>
                  <p>{{ $t("com_p6") }}</p>
                  <p>{{ $t("com_p7") }}</p>
                  <p>{{ $t("com_p8") }}</p>
                  <p>{{ $t("com_p9") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("obligation_comply") }}</strong>
                  </p>
                  <p>{{ $t("ob_p1") }}</p>
                  <p>{{ $t("ob_p2") }}</p>
                  <p>{{ $t("ob_p3") }}</p>
                  <p>{{ $t("ob_p4") }}</p>
                  <p>{{ $t("ob_p5") }}</p>
                  <p>{{ $t("ob_p6") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("resp_processing_personal_data") }}</strong>
                  </p>
                  <p>{{ $t("rpp_p1") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("non-p_information_security") }}</strong>
                  </p>
                  <p>{{ $t("nonp_p1") }}</p>
                  <p>{{ $t("nonp_p2") }}</p>
                  <p>{{ $t("nonp_p3") }}</p>
                  <p>{{ $t("nonp_p4") }}</p>
                  <p>{{ $t("nonp_p5") }}</p>
                  <p>{{ $t("nonp_p6") }}</p>
                </li>
                <li>
                  <p>
                    <strong>{{ $t("effective_date_policy") }}</strong>
                  </p>
                  <p>{{ $t("edp_p1") }}</p>
                  <p>{{ $t("edp_p2") }}</p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PrivacyPolicyContent",
};
</script>
