<template>
  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_heading">
              <h2>{{ $t("register_account") }}</h2>
            </div>
            <div class="common_author_form">
              <form @submit.prevent="registerUser" id="main_author_form">
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    :placeholder="$t('name')"
                    v-model="formData.name"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    class="form-control"
                    :placeholder="$t('email')"
                    v-model="formData.email"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="$t('password')"
                    v-model="formData.password"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="password"
                    class="form-control"
                    :placeholder="$t('confirm_password')"
                    v-model="formData.confirmPassword"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    type="date"
                    value=""
                    class="form-control bg_input"
                    v-model="formData.birthDate"
                  />
                  <sub for="dates">{{ $t("birthdate") }}</sub>
                </div>

                <div class="common_form_submit">
                  <button type="submit" class="btn btn_theme btn_md">
                    {{ $t("register") }}
                  </button>
                </div>
                <div class="have_acount_area other_author_option">
                  <p>
                    {{ $t("already_have_account") }}
                    <router-link to="/login">{{ $t("log_in_now") }}</router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { mapActions } from "vuex";
import { API_BASE_URL, REGISTER } from "@/api-config";
import Swal from "sweetalert2";

export default {
  name: "CommonAuthorThree",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        password: "",
        confirmPassword: "",
        birthDate: "",
      },
    };
  },
  computed: {
    formatDate() {
      if (!this.formData.birthDate) return "";
      const [year, month, day] = this.formData.birthDate.split("-");
      return `${year}/${month}/${day}`;
    },
  },
  methods: {
    ...mapActions("form", ["updateError"]),

    showAlert(message, type) {
      Swal.fire({
        icon: type,
        title: type === "success" ? "Éxito" : "Error",
        text: message,
      }).then(() => {
        this.updateError({ status: false, message: "" });
      });
    },
    async registerUser() {
      try {
        const response = await axios.post(`${API_BASE_URL}${REGISTER}`, {
          name: this.formData.name,
          email: this.formData.email,
          password: this.formData.password,
          password_confirmed: this.formData.confirmPassword,
          birth_date: this.formatDate,
        });

        console.log("Response data:", response.data);

        if (!response.data.errors) {
          this.showAlert("Registration successful!", "success");
          this.$router.push("/login");
        }
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          this.showAlert(errorMessage, "error");
        } else {
          this.showAlert("Error desconocido");
        }
      }
    },
  },
};
</script>
