<template>
  <section id="tour_details_main" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="section_heading_center">
            <h2>{{ $t("about_us") }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="terms_service_content">
            <div class="terms_item">
              <h4>{{ $t("us") }}</h4>
              <p style="text-align: justify">
                {{ $t("us_p1") }}
              </p>
              <p style="text-align: justify">
                {{ $t("us_p2") }}
              </p>
              <p style="text-align: justify">
                {{ $t("us_p3") }}
              </p>
              <p style="text-align: justify">
                {{ $t("us_p4") }}
              </p>
              <p style="text-align: justify">
                {{ $t("us_p5") }}
              </p>
              <p style="text-align: justify">
                {{ $t("us_p6") }}
              </p>
              <p style="text-align: center">
                {{ $t("us_p7") }}
              </p>
            </div>
            <div class="terms_item">
              <h4>{{ $t("value_proposition") }}</h4>
              <p style="text-align: justify">
                {{ $t("pv_p1") }}
              </p>
              <p style="text-align: justify">
                {{ $t("pv_p2") }}
              </p>
              <p style="text-align: justify">
                {{ $t("pv_p3") }}
              </p>
              <p style="text-align: justify">
                {{ $t("pv_p4") }}
              </p>
              <p style="text-align: justify">
                {{ $t("pv_p5") }}
              </p>
              <p style="text-align: justify">
                {{ $t("pv_p6") }}
              </p>
            </div>
            <div class="terms_item">
              <h4>{{ $t("our_pillars") }}</h4>

              <p style="text-align: justify">
                {{ $t("np_p1") }}
              </p>
              <p style="text-align: justify">
                {{ $t("np_p2") }}
              </p>
              <p style="text-align: justify">
                {{ $t("np_p3") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "PrivacyPolicyContent",
};
</script>
