<template>
  <div>
    <header class="main_header_arae">
      <div class="navbar-area is-sticky">
        <div class="main-responsive-nav">
          <div class="container">
            <div class="main-responsive-menu mean-container">
              <nav class="navbar">
                <div class="container">
                  <div class="logo">
                    <router-link to="/">
                      <img :src="require('../assets/img/logo.png')" alt="logo" />
                    </router-link>
                  </div>
                  <button
                    class="navbar-toggler collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbar-content"
                  >
                    <div class="hamburger-toggle">
                      <div class="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  </button>
                  <div class="collapse navbar-collapse mean-nav" id="navbar-content">
                    <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
                      <li class="nav-item dropdown">
                        <router-link to="/" class="dropdown-item dropdown-toggle">{{
                          $t("home")
                        }}</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="#" class="dropdown-item">{{
                          $t("clients")
                        }}</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/about-us" class="dropdown-item">{{
                          $t("about_us")
                        }}</router-link>
                      </li>
                      <li class="nav-item">
                        <router-link to="/contact" class="dropdown-item">{{
                          $t("contact_us")
                        }}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
        <div class="main-navbar">
          <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
              <router-link class="navbar-brand" to="/">
                <img :src="require('@/assets/img/logo.png')" alt="logo" />
              </router-link>
              <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                <ul class="navbar-nav mx-4">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link">
                      {{ $t("home") }}
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="#" class="nav-link">{{
                      $t("clients")
                    }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/about-us" class="nav-link">{{
                      $t("about_us")
                    }}</router-link>
                  </li>
                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link">{{
                      $t("contact_us")
                    }}</router-link>
                  </li>
                </ul>
                <div
                  class="outline-btn rounded-pill py-3 mx-4 d-flex align-content-center"
                  v-if="!isAuthenticated"
                >
                  <ul class="navbar-nav btn-btn mx-3">
                    <li class="nav-item">
                      <router-link to="/login" class="nav-link">{{
                        $t("login")
                      }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link to="/register" class="nav-link">{{
                        $t("sign_up")
                      }}</router-link>
                    </li>
                  </ul>
                </div>
                <div
                  class="outline-btn rounded-pill py-3 mx-4 d-flex align-content-center"
                  v-else
                >
                  <ul class="navbar-nav btn-btn mx-3">
                    <li class="nav-item">
                      <router-link to="#" class="nav-link">{{ userName }}</router-link>
                    </li>
                    <li class="nav-item">
                      <router-link
                        to="/"
                        class="nav-link"
                        @click.prevent="handleLogout"
                        >{{ $t("logout") }}</router-link
                      >
                    </li>
                  </ul>
                </div>

                <ul class="topbar-list btn-btn">
                  <li>
                    <a href="#!"><i class="fab fa-facebook-f"></i></a>
                    <a href="#!"> <i class="fa-brands fa-x-twitter"></i></a>
                    <a href="#!"><i class="fab fa-instagram"></i></a>
                    <a href="#!"><i class="fab fa-linkedin-in"></i></a>
                  </li>
                  <li>
                    <div class="dropdown">
                      <button class="dropdown-button">
                        <img id="selected-flag" :src="flagUrl" :alt="language" />
                      </button>
                      <div class="dropdown-content">
                        <a
                          class="router-link-active router-link-exact-active nav-link"
                          @click="changeLanguage('es')"
                          :data-image="require('../assets/img/flags/es.svg')"
                          data-text="ES"
                          :class="{
                            'dropdown-content-hover': language == 'es',
                          }"
                        >
                          <img src="../assets/img/flags/es.svg" alt="es" />
                          {{ $t("spanish") }}
                        </a>
                        <a
                          class="router-link-active router-link-exact-active nav-link"
                          @click="changeLanguage('us')"
                          data-text="US"
                          :data-image="require('../assets/img/flags/us.svg')"
                          :class="{
                            'dropdown-content-hover': language == 'us',
                          }"
                        >
                          <img src="../assets/img/flags/us.svg" alt="US" />
                          {{ $t("english") }}
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { RouterLink } from "vue-router";
import i18n from "./../translates/translationService";

export default {
  name: "Header",
  data() {
    return {
      language: "es",
      currency: "COP",
      isSticky: false,
    };
  },
  computed: {
    ...mapState("autentication", ["isAuth", "name"]),
    isAuthenticated() {
      return this.isAuth;
    },
    flagUrl() {
      return require(`../assets/img/flags/${this.language}.svg`);
    },
    userName() {
      return localStorage.getItem("name");
    },
  },

  mounted() {
    let lang = localStorage.getItem("lang");
    if (lang == undefined) {
      localStorage.setItem("lang", "es");
      lang = "es";
    }
    this.language = lang;
    this.$i18n.locale = this.language;

    document.addEventListener("click", function (e) {
      if (e.target.classList.contains("hamburger-toggle")) {
        e.target.children[0].classList.toggle("active");
      }
    });
    const dropdownButton = document.querySelector(".dropdown-button");
    const dropdownContent = document.querySelector(".dropdown-content");
    const selectedFlag = document.getElementById("selected-flag");

    dropdownButton.addEventListener("click", () => {
      dropdownContent.style.display =
        dropdownContent.style.display === "block" ? "none" : "block";
    });

    window.addEventListener("click", (event) => {
      if (!event.target.closest(".dropdown")) {
        dropdownContent.style.display = "none";
      }
    });

    document.querySelectorAll(".dropdown-content a").forEach((option) => {
      option.addEventListener("click", (event) => {
        event.preventDefault();
        const imageSrc = option.getAttribute("data-image");
        console.log(imageSrc);
        selectedFlag.src = imageSrc;
        dropdownContent.style.display = "none";
      });
    });
  },

  methods: {
    ...mapActions("autentication", ["logout"]),
    async handleLogout() {
      try {
        await this.logout();
        this.$router.push("/login");
        this.$forceUpdate();
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          const errorMessage = error.response.data.message;
          this.showAlert(errorMessage, "error");
        } else {
          this.showAlert("Error desconocido");
        }
      }
    },
    changeLanguage(lang) {
      this.language = lang;
      if (this.$i18n) {
        this.$i18n.locale = lang;
        localStorage.setItem("lang", lang);
      }
    },
  },
};
</script>

<style scoped>
.mean-menu {
  justify-content: flex-start;
}

ul.btn-btn {
  padding-left: 0px !important;
}

.outline-btn {
  border: 2px solid var(--black-color);
  background-color: transparent;
  color: var(--black-color);
  transition: color 0.3s, background-color 0.3s;
}

.outline-btn:hover {
  border: 2px solid var(--coral-red-color);
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  padding: 10px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  display: flex;
  align-items: center;
  background-color: transparent !important;
}

.dropdown-button img {
  width: 40px;
  height: 30px;
  border-radius: 15%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.dropdown-content a img {
  width: 40px;
  height: 30px;
  margin-right: 10px;
  border-radius: 10%;
}

.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown-content-hover {
  background-color: #ddd;
}
</style>
