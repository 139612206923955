import { createStore } from "vuex";
import form from "./modules/form";
import autentication from "./modules/autentication";

export default createStore({
	modules: {
		form,
		autentication,
	},
});
