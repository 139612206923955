<template>
  <section id="promotional_tours" class="section_padding_top">
    <div class="container">
      <div class="row pb-5">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <div>
            <h2 class="text-coral">{{ $t("recommended") }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div
            class="promotional_tour_slider owl-theme owl-carousel dot_style"
            style="height: 700px"
          >
            <swiper
              :slides-per-view="3"
              :slides-per-group="3"
              :space-between="25"
              :navigation="{
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
              }"
              :mousewheel="true"
              :preventClicks="true"
              :preventClicksPropagation="true"
              :breakpoints="{
                320: { slidesPerView: 1, spaceBetween: 10 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 25 },
              }"
              class="mySwiper"
            >
              <swiper-slide
                v-for="(hotelInfo, index) in items"
                :key="index"
                :class="getSlideClass(index)"
              >
                <div class="image-container">
                  <router-link
                    :to="{
                      name: `hotel-details`,
                      params: { id: hotelInfo.id, key: hotelInfo.key },
                      query: {
                        start_date: defaultStartDate,
                        end_date: defaultEndDate,
                        rooms: rooms,
                        guests: serializeGuests(guests),
                        customerNationality: customerNationality,
                      },
                    }"
                  >
                    <img :src="hotelInfo.imagen" alt="img" />
                  </router-link>
                </div>
                <div class="theme_two_box_content">
                  <h4>
                    <router-link
                      :to="{
                        name: `hotel-details`,
                        params: { id: hotelInfo.id, key: hotelInfo.key },
                      }"
                    >
                      {{ hotelInfo.name }}
                    </router-link>
                  </h4>
                  <h3 class="text-blue-violet">
                    {{ hotelInfo.price }}
                    <span class="text-black fs-4">{{ $t("per_night") }}</span>
                  </h3>
                </div>
              </swiper-slide>
              <div class="swiper-button-next swiper-no-swiping"></div>
              <div class="swiper-button-prev swiper-no-swiping"></div>
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import axios from "axios";
import { API_BASE_URL, RECOMMENDED_PROPERTIES_API } from "@/api-config";
import SwiperCore, { Navigation, Mousewheel } from "swiper";

SwiperCore.use([Navigation, Mousewheel]);

export default {
  name: "RecommendedProperties",
  components: { Swiper, SwiperSlide },
  data() {
    const currentDate = new Date();
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 30);

    return {
      swiper: null,
      items: [],
      defaultStartDate: currentDate.toISOString().slice(0, 10),
      defaultEndDate: endDate.toISOString().slice(0, 10),
      customerNationality: "CO",
      rooms: 1,
      guests: [{ adults: 1, children: [] }],
    };
  },
  mounted() {
    this.fetchPropertyRecomended();
  },
  methods: {
    getSlideClass(index) {
      const classes = ["slide1", "slide2", "slide3"];
      return classes[index % 3];
    },

    serializeGuests(guestsArray) {
      return guestsArray
        .map((guest) => `adults=[${guest.adults}],children=[${guest.children.join(",")}]`)
        .join("&");
    },

    fetchPropertyRecomended() {
      axios
        .get(`${API_BASE_URL}${RECOMMENDED_PROPERTIES_API}`)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {
          console.error("Error fetching properties:", error);
        });
    },
  },
};
</script>

<style scoped>
.mySwiper {
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .slide1 {
    width: 49% !important;
    height: 700px !important;
  }

  .slide2 {
    width: 29% !important;
    height: 700px !important;
  }

  .slide3 {
    width: 19% !important;
    height: 700px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .slide1,
  .slide2,
  .slide3 {
    width: 48% !important;
    height: 300px !important;
  }
}

@media (max-width: 767px) {
  .slide1,
  .slide2,
  .slide3 {
    width: 100% !important;
    height: 250px !important;
  }
}

.image-container {
  height: 80%;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
}

.theme_two_box_content {
  padding: 10px 0 0 5px;
}
</style>
