<template>
  <!-- Banner Area -->
  <Banner />

  <!-- Form Area -->
  <Form />

  <!-- imagination Area -->
  <Imagination />

  <!-- Top destinations -->
  <TopDestinations />

  <OutstandingProperties />

  <RecommendedProperties />

  <NewsArea />

  <Testimonials />
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/home/Banner.vue";
import Form from "@/components/home/Form.vue";
import Imagination from "@/components/home/Imagination.vue";
import TopDestinations from "@/components/home/TopDestinations.vue";
import RecommendedProperties from "@/components/home/RecommendedProperties.vue";
import OutstandingProperties from "@/components/home/OutstandingProperties.vue";
import NewsArea from "@/components/home/NewsArea.vue";
import Testimonials from "@/components/pages/Testimonials.vue";

export default {
  name: "HomeView",
  components: {
    Banner,
    Form,
    Imagination,
    TopDestinations,
    RecommendedProperties,
    OutstandingProperties,
    NewsArea,
    Testimonials,
  },
};
</script>
