<template>
  <section id="common_author_area" class="section_padding">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="common_author_boxed">
            <div class="common_author_heading">
              <h2>{{ $t("login_your_account") }}</h2>
            </div>
            <div class="common_author_form">
              <form @submit.prevent="handleLogin">
                <div class="form-group">
                  <input
                    v-model="email"
                    type="text"
                    class="form-control"
                    :placeholder="$t('email')"
                    required
                  />
                </div>
                <div class="form-group">
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                    :placeholder="$t('password')"
                    required
                  />
                  <router-link to="#">{{ $t("forgot_password") }}</router-link>
                </div>
                <div class="common_form_submit">
                  <button
                    type="submit"
                    class="btn btn_theme btn_md"
                    :disabled="isLoading"
                  >
                    <span v-if="isLoading">{{ $t("loading") }}</span>
                    <span v-else>{{ $t("log_in") }}</span>
                  </button>
                </div>
                <div v-if="errorMessage" class="error_message">
                  {{ errorMessage }}
                </div>
                <div class="have_acount_area">
                  <p>
                    {{ $t("dont_have_an_account") }}
                    <router-link to="/register">{{ $t("register_now") }}</router-link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "CommonAuthorFour",
  data() {
    return {
      email: "",
      password: "",
      errorMessage: null,
    };
  },
  computed: {
    ...mapState("autentication", ["isLoading"]),
  },
  methods: {
    ...mapActions("autentication", ["login"]),
    ...mapActions("form", ["updateError"]),

    showAlert(message, type) {
      Swal.fire({
        icon: type,
        title: type === "success" ? "Éxito" : "Error",
        text: message,
      }).then(() => {
        this.updateError({ status: false, message: "" });
      });
    },

    async handleLogin() {
      this.errorMessage = null;
      try {
        await this.login({ email: this.email, password: this.password });
        this.showAlert("Login successful!", "success");
        this.$router.push({ name: "home" });
      } catch (error) {
        this.showAlert(error.message, "error");
        console.error("Error al iniciar sesión:", error);
      }
    },
  },
};
</script>

<style scoped>
.error_message {
  color: red;
  margin-top: 10px;
}
</style>
