<template>
	<section id="testimonials_main_arae" class="testimonials">
		<div class="container py-5">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 col-12">
					<h2 class="text-white">{{ $t("testimonials") }}</h2>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-12">
					<div class="pagination_area">
						<ul class="pagination pagination-testimonials">
							<li class="page-item" :class="{ disabled: currentPage === 1 }">
								<a
									class="page-link page-link-testimonials"
									aria-label="Previous"
									@click="changePage(currentPage - 1)"
									:disabled="currentPage === 1"
								>
									<i class="p-1 fs-5 fas fa-angle-left text-coral"></i>
								</a>
							</li>
							<li
								class="page-item"
								:class="{ disabled: currentPage === totalPages }"
							>
								<a
									class="page-link page-link-testimonials"
									aria-label="Next"
									@click="changePage(currentPage + 1)"
									:disabled="currentPage === totalPages"
								>
									<i class="p-1 fs-5 fas fa-angle-right text-coral"></i>
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div
					v-for="(testimonialContent, index) in items"
					:key="index"
					class="col-lg-4 col-md-6"
				>
					<div class="all_review_box all_review_box_testimonials">
						<div class="all_review_date_area">
							<div class="all_review_date">
								<h5>{{ replaceHyphens(testimonialContent.date) }}</h5>
							</div>
							<div class="all_review_star">
								<div class="review_star_all star_testimonials">
									<i
										class="fas text-golden fa-star"
										v-for="n in testimonialContent.scores"
										:key="n"
									></i>
								</div>
							</div>
						</div>
						<div class="all_review_text">
							<h5 class="mt-4">
								{{ testimonialContent.customer ?? testimonialContent.property }}<br>
							</h5>
							<div style="height: 130px;overflow: hidden;">
								<p>"{{ truncateText(testimonialContent.feedback, 200) }}"</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import axios from "axios";
	import { API_BASE_URL, GET_TESTIMONIALS_API } from "@/api-config";
	export default {
		name: "Testimonials",
		data() {
			return {
				items: [],
				currentPage: 1,
				totalPages: 1,
				itemsPerPage: 3,
				paginationLinks: {
					first: null,
					last: null,
					prev: null,
					next: null,
					pages: [],
				},
				minScore: 4,
			};
		},

		mounted() {
			if (this.$route.params.id == undefined) {
				this.fetchTestimonials();
			} else {
				this.fetchTestimonialProperty();
			}
		},
		methods: {
			replaceHyphens(text) {
				return text.replace(/-/g, ' ');
			},
			truncateText(text, length) {
				console.log(111,length);
			return text.length > length ? text.substring(0, length) + "..." : text;
			},
			changePage(page) {
				if (this.$route.params.id == undefined) {
					this.fetchTestimonials(page);
				} else {
					this.fetchTestimonialProperty(page);
				}
			},
			fetchTestimonials(page) {
				axios
					.get(`${API_BASE_URL}${GET_TESTIMONIALS_API}`, {
						params: {
							min_scores: this.minScore,
							per_page: this.itemsPerPage,
							page: page,
						},
					})
					.then((response) => {
						this.items = response.data.data;
						this.currentPage = response.data.meta.current_page;
						this.totalPages = response.data.meta.last_page;

						response.data.meta.links.shift();
						response.data.meta.links.pop();

						this.paginationLinks = {
							first: response.data.links.first,
							last: response.data.links.last,
							prev: response.data.links.prev,
							next: response.data.links.next,
							pages: response.data.meta.links,
						};

						this.paginationLinks.pages;
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
			fetchTestimonialProperty(page = 1) {
				axios
					.get(
						`${API_BASE_URL}${GET_TESTIMONIALS_API}/${this.$route.params.id}`,
						{
							params: {
								per_page: this.itemsPerPage,
								page: page,
							},
						}
					)
					.then((response) => {
						this.items = response.data.data;
						this.currentPage = response.data.meta.current_page;
						this.totalPages = response.data.meta.last_page;

						response.data.meta.links.shift();
						response.data.meta.links.pop();

						this.paginationLinks = {
							first: response.data.links.first,
							last: response.data.links.last,
							prev: response.data.links.prev,
							next: response.data.links.next,
							pages: response.data.meta.links,
						};
					})
					.catch((error) => {
						console.error("Error fetching properties:", error);
					});
			},
		},
	};
</script>
