import axios from "axios";
import { API_BASE_URL, AUTH_API, LOGOUT_API } from "@/api-config";

const state = {
	name: localStorage.getItem("name") || "",
	email: "",
	photoAccess: null,
	isAuth: !!localStorage.getItem("token"),
	token: null,
	isLoading: false,
};

const mutations = {
	setName(state, name) {
		state.name = name;
	},
	setEmail(state, email) {
		state.email = email;
	},
	setPhotoAccess(state, photoAccess) {
		state.photoAccess = photoAccess;
	},
	setAuth(state, isAuth) {
		state.isAuth = isAuth;

	},
	setToken(state, token) {
		state.token = token;
	},
	toggleLoading(state) {
		state.isLoading = !state.isLoading;
	},
	setUser(state, user) {
		state.name = user.name;
		state.email = user.email;
		state.photoAccess = user.photo_access;
		state.isAuth = true;
	},
	clearAuthData(state) {
		state.name = "";
		state.email = "";
		state.photoAccess = null;
		state.isAuth = false;
		state.token = null;
		localStorage.removeItem("token");
		localStorage.removeItem("name");
	},
};

const actions = {
	async login({ commit }, { email, password }) {
		commit("toggleLoading");
		try {
			const response = await axios.post(`${API_BASE_URL}${AUTH_API}`, {
				email,
				password,
			});
			const data = response.data;
			localStorage.setItem("token", data.token);
			localStorage.setItem("name", data.user.name);

			commit("setToken", data.token);
			commit("setAuth", { isAuth: true});
		} catch (error) {
			const errorMessage = error.response?.data?.errors?.email || error.response?.data?.message;
			throw new Error(errorMessage);
		} finally {
			commit("toggleLoading");
		}
	},

	async logout({ commit }) {
		const authToken = localStorage.getItem("token");
		try {
			await axios.post(
				`${API_BASE_URL}${LOGOUT_API}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${authToken}`,
					},
				}
			);
			commit("clearAuthData");
		} catch (error) {
			console.error("Error al cerrar sesión:", error);
		}
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
};
